var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('translation-locales'), _c('v-card-text', [_vm.resource ? _c('p', {
    staticClass: "mt-4"
  }, [_vm._v(" The items below cannot be updated once created. ")]) : _vm._e(), _c('v-autocomplete', {
    staticClass: "mt-5",
    attrs: {
      "items": _vm.siteSectionsList,
      "item-text": "key",
      "item-value": "id",
      "label": "Select Section",
      "outlined": "",
      "hide-details": "",
      "disabled": !!_vm.resource
    },
    model: {
      value: _vm.form.section_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "section_id", $$v);
      },
      expression: "form.section_id"
    }
  }), _vm.siteItemRulesList ? _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "items": _vm.siteItemRulesList.elements,
      "label": "Select Type",
      "rules": [_vm.required],
      "outlined": "",
      "hide-details": "",
      "disabled": !!_vm.resource
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }) : _vm._e(), _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "items": _vm.filteredSiteItemsList,
      "item-text": "key",
      "item-value": "id",
      "label": "Select Parent Item",
      "outlined": "",
      "hide-details": "",
      "disabled": !!_vm.resource
    },
    model: {
      value: _vm.form.parent_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "parent_id", $$v);
      },
      expression: "form.parent_id"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Key",
      "outlined": "",
      "rules": [_vm.required],
      "disabled": !!_vm.resource
    },
    model: {
      value: _vm.form.key,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "key", $$v);
      },
      expression: "form.key"
    }
  }), _c('hr', {
    staticClass: "mt-4 mb-4"
  }), _c('v-select', {
    staticClass: "mt-2",
    attrs: {
      "items": _vm.featuresOptions,
      "item-text": "key",
      "item-value": "id",
      "label": "Required Feature (empty=all visible)",
      "clearable": ""
    },
    model: {
      value: _vm.form.feature_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "feature_id", $$v);
      },
      expression: "form.feature_id"
    }
  }), _vm.selectedItemRules ? _c('div', [_vm._l(_vm.selectedItemRules, function (value, key) {
    return [key === 'class' ? _c('div') : key === 'style' ? _c('div') : key == 'content' && !value.includes('array') ? _c('v-textarea', {
      attrs: {
        "label": key,
        "outlined": ""
      },
      model: {
        value: _vm.form.element[key],
        callback: function callback($$v) {
          _vm.$set(_vm.form.element, key, $$v);
        },
        expression: "form.element[key]"
      }
    }) : value.includes('string') ? _c('v-text-field', {
      staticClass: "mt-5",
      attrs: {
        "label": "href",
        "hint": "To Path after click. Can leave empty if not a link.",
        "outlined": ""
      },
      model: {
        value: _vm.form.element[key],
        callback: function callback($$v) {
          _vm.$set(_vm.form.element, key, $$v);
        },
        expression: "form.element[key]"
      }
    }) : key == 'src' && value.includes('array') ? _c('translatable-input', {
      attrs: {
        "label": "image src (external)",
        "hint": "If an asset (internal) is associated, it will override the external src.",
        "outlined": "",
        "disabled": !!_vm.form.asset_id
      },
      model: {
        value: _vm.form.element[key],
        callback: function callback($$v) {
          _vm.$set(_vm.form.element, key, $$v);
        },
        expression: "form.element[key]"
      }
    }) : key == 'text' && value.includes('array') ? _c('translatable-editor', {
      attrs: {
        "label": key,
        "outlined": ""
      },
      model: {
        value: _vm.form.element[key],
        callback: function callback($$v) {
          _vm.$set(_vm.form.element, key, $$v);
        },
        expression: "form.element[key]"
      }
    }) : value.includes('array') ? _c('v-text-field', {
      staticClass: "mt-5",
      attrs: {
        "label": key,
        "outlined": ""
      },
      model: {
        value: _vm.form.element[key],
        callback: function callback($$v) {
          _vm.$set(_vm.form.element, key, $$v);
        },
        expression: "form.element[key]"
      }
    }) : _c('div', {
      staticClass: "mt-5",
      attrs: {
        "label": key,
        "outlined": ""
      },
      model: {
        value: _vm.form.element[key],
        callback: function callback($$v) {
          _vm.$set(_vm.form.element, key, $$v);
        },
        expression: "form.element[key]"
      }
    })];
  })], 2) : _vm._e(), _c('v-select', {
    attrs: {
      "items": _vm.siteAssetsList,
      "item-text": "notes",
      "item-value": "id",
      "label": "Asset - associate image item with highly reusable & fixed image internal asset",
      "disabled": _vm.form.type !== 'image'
    },
    model: {
      value: _vm.form.asset_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "asset_id", $$v);
      },
      expression: "form.asset_id"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "type": "number",
      "label": "Sort",
      "outlined": "",
      "hint": "The sort position (ASC)"
    },
    model: {
      value: _vm.form.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sort", $$v);
      },
      expression: "form.sort"
    }
  }), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-switch', {
    model: {
      value: _vm.form.auth,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "auth", $$v);
      },
      expression: "form.auth"
    }
  }), _vm._v(" Login user visible "), _vm.form.auth ? _c('v-select', {
    staticClass: "ml-2",
    attrs: {
      "items": _vm.authStateOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "When",
      "clearable": ""
    },
    model: {
      value: _vm.form.auth_state,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "auth_state", $$v);
      },
      expression: "form.auth_state"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-switch', {
    model: {
      value: _vm.form.guest,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "guest", $$v);
      },
      expression: "form.guest"
    }
  }), _vm._v(" Guest visible ")], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-switch', {
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  }), _vm._v(" Active ")], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }