import { t } from '@/plugins/i18n'

export default () => {
  const isExternalImageItem = item => !item.asset_id && item.type === 'image'

  const getImageSrc = item => {
    let src = ''
    if (item.asset) {
      src = item.asset.instance.thumbnail
    } else if (isExternalImageItem(item)) {
      src = t(item.element.src)
    }

    return src
  }

  return {
    isExternalImageItem,
    getImageSrc,
  }
}
