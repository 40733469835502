import { destroySiteItem, fetchSiteItems } from '@/api/site/site'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default (query = {}) => {
  const loading = ref(false)

  const tableColumns = [
    {
      text: 'Key',
      value: 'key',
    },
    {
      text: 'Type',
      value: 'type',
    },
    {
      text: 'Asset',
      value: 'asset_id',
    },
    {
      text: 'Text',
      value: 'element.text',
      sortable: false,
    },
    {
      text: 'src/href',
      value: 'src_href',
      sortable: false,
    },

    // {
    //   text: 'ele',
    //   value: 'element',
    // },
    {
      text: 'Parent ID ',
      value: 'parent_id',
    },
    {
      text: 'Required Feature',
      value: 'feature_id',
    },
    {
      text: 'Sort',
      value: 'sort',
    },
    {
      text: 'Active',
      value: 'active',
    },
    {
      text: 'Reserved',
      value: 'reserved',
    },
    {
      text: 'Notes',
      value: 'notes',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = reactive(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: 999,
    },
  )

  // Filter
  const filters = reactive({
    site_id: null,
    page_id: null,
    section_id: null,
    sort: '+sort',
  })

  const loadData = async () => {
    loading.value = true

    try {
      const { data } = await fetchSiteItems(
        useTableOptions(options, {
          ...parseFilters(),
          ...query,
        }),
      )

      if (data.status === 'success') {
        tableItems.value = data.data.records
        tableTotal.value = data.data.pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce(
        (a, key) => ({
          ...a,
          [key]: filters[key],
        }),
        {},
      )

  const deleteSiteItems = obj =>
    destroySiteItem(obj.id)
      .then(() => {
        useNotifySuccess({
          content: 'Delete Success',
        })
        loadData()
      })
      .catch(useNotifyErrors)

  watch([options], (newVal, oldVal) => {
    if (Object.keys(oldVal[0]).length > 3) {
      loadData()
    }
  })

  watch(
    () => filters.section_id,
    debounce(() => {
      loadData()
    }, 1),
  )
  watch(
    () => filters.page_id,
    debounce(() => {
      loadData()
    }, 1),
  )

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,

    loadData,
    deleteSiteItems,
  }
}
